import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/gpyhs.js'


import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import ajax from './utils/ajax.js';
Vue.prototype.$ajax = new ajax;

import Print from 'vue-print-nb'
Vue.use(Print);  //打印

import VueQuillEditor from 'vue-quill-editor' //富文本
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

Vue.config.productionTip = false

let vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vm;
