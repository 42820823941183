import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//核心：解决代码冗余
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch(error => error)
}
const routes = [
  {path: '/',redirect:'/login'},
  {path: '/login',name: 'login',component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')},
	{
	  path: '/home',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../views/home/home.vue'),
		children:[
			{path: '/home/',name: 'ClassManagement',component: () => import(/* webpackChunkName: "ClassManagement" */ '../views/ClassManagement/ClassManagement.vue')},
			{path: '/home/user',name: 'user',component: () => import(/* webpackChunkName: "user" */ '../views/user/user.vue')},
			{path: '/home/password',name: 'password',component: () => import(/* webpackChunkName: "password" */ '../views/password/password.vue')},
			{path: '/home/administrators',name: 'administrators',component: () => import(/* webpackChunkName: "administrators" */ '../views/administrators/administrators.vue')},
			{path: '/home/statement',name: 'statement',component: () => import(/* webpackChunkName: "statement" */ '../views/statement/statement.vue')},
			{path: '/home/enroll',name: 'enroll',component: () => import(/* webpackChunkName: "enroll" */ '../views/ClassManagement/enroll.vue')},
			{path: '/home/CourseManagement',name: 'CourseManagement',component: () => import(/* webpackChunkName: "CourseManagement" */ '../views/CourseManagement/CourseManagement.vue')},
			{path: '/home/StudentManagement',name: 'StudentManagement',component: () => import(/* webpackChunkName: "StudentManagement" */ '../views/StudentManagement/StudentManagement.vue')},
			{path: '/home/addStudent',name: 'addStudent',component: () => import(/* webpackChunkName: "addStudent" */ '../views/StudentManagement/addStudent.vue')},
			{path: '/home/entry',name: 'entry',component: () => import(/* webpackChunkName: "entry" */ '../views/StudentManagement/entry.vue')},
			{path: '/home/TeacherManagement',name: 'TeacherManagement',component: () => import(/* webpackChunkName: "TeacherManagement" */ '../views/TeacherManagement/TeacherManagement.vue')},
			{path: '/home/user',name: 'user',component: () => import(/* webpackChunkName: "user" */ '../views/user/user.vue')},
			{path: '/home/RegistrationRecord',name: 'RegistrationRecord',component: () => import(/* webpackChunkName: "RegistrationRecord" */ '../views/RegistrationRecord/RegistrationRecord.vue')},
			{path: '/home/blacklist',name: 'blacklist',component: () => import(/* webpackChunkName: "blacklist" */ '../views/blacklist/blacklist.vue')},
			{path: '/home/schedule',name: 'schedule',component: () => import(/* webpackChunkName: "schedule" */ '../views/schedule/schedule.vue')},
		]
	}

]


const router = new VueRouter({
mode: 'hash',
base: process.env.BASE_URL,
  routes
})

export default router
